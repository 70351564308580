<template>
  <SubPageWrapper empty-cols>
    <template #breadcrumbs>
      <SlBreadcrumb @click="goToProjectsList">
        {{ $t('Main.Ui.btStartPageProjects') }}
      </SlBreadcrumb>
      <SlBreadcrumb
        :index="1"
        active
      >
        {{ $t('CreateNewProject.lbDataSourceTitle') }}
      </SlBreadcrumb>
    </template>
    <div class="new-connection">
      <div class="new-connection__top">
        <div class="new-connection__top-description body-1 grey-70">
          {{ $t('Web.DbImport.TextSelectSource') }}
        </div>
      </div>
      <div class="new-connection__items">
        <div
          v-for="connector in filteredConnections"
          :key="connector.key"
          class="connection-item"
          @click="handleConnectionSelect(connector.key)"
        >
          <div class="connection-item__icon-wrapper">
            <icon
              :data="connector.icon"
              class="connection-item__icon size-72"
              :class="connectorIconClass(connector.key)"
            />
          </div>
          <div class="connection-item__title body-3-md grey-60">
            {{ connector.title }}
          </div>
        </div>
      </div>
    </div>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import {
  connectionsConfig,
  connectorByType,
  releasedConnectors,
  connectionTypes
} from '@/config/connection';
import { sendRequests } from '@/helpers/lastChange/sendRequests.js';
import { componentNames } from '@/config/app.config.js';
import { connectorRouteNames, routeNames } from '@/config/router/router.config';

export default {
  name: 'Index',
  mixins: [modal],
  data() {
    return {
      modalsId
    };
  },
  computed: {
    ...mapState({
      availableConnectors: state => state.manageProjects.availableConnectors || [],
      selectedConnector: state => state.connection.selectedConnector
    }),
    connections() {
      return connectionsConfig(this);
    },
    filteredConnections() {
      return this.availableConnectors.reduce((acc, connector) => {
        if (this.connections[connector]) {
          acc.push(this.connections[connector]);
        }

        return acc;
      }, []);
    },
    connectorsData() {
      return connectorByType(this);
    }
  },
  async beforeMount() {
    await sendRequests(componentNames.ROOT);
  },
  methods: {
    ...mapActions({
      resetState: 'connection/resetState',
      selectConnector: 'connection/selectConnector'
    }),
    connectorIconClass(key) {
      if (key !== connectionTypes.CUSTOM_API) {
        return '';
      }

      return 'fill-off';
    },
    async handleConnectionSelect(key) {
      await this.resetState();
      await this.selectConnector(key);

      const connector = this.connectorsData[key];

      if (!releasedConnectors.includes(key)) {
        return;
      }

      if (connector.modal) {
        return this.showModal(connector.modal, { isNew: true });
      }

      this.$router.push({
        name: connectorRouteNames.CREATE_PROJECT_CONNECTOR,
        params: {
          connector: key
        }
      });
    },
    goToProjectsList() {
      this.$router.push({
        name: routeNames.PROJECT_LIST
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/connections/new-connection.scss";
</style>
